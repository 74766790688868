import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';

import md5 from 'md5';
import {
  loaderAction, authUser, changeAuthStep, setUser,
  logoutAction, updateUser, setAuthError,
  updateUserData,
  updateAdressData,
  setActiveModalAction,
  updateUserPoint,
  cancelOrder,
  authSuccess,
  fetchUser,
  fetchOrdersUser,
  changeAuthNumber,
} from '../actions';
import { restUrls } from '../constants';
import { fromStore, settingsSelector } from '../selectors';
import {
  deleteCookie, getCookie, regexpPhone, request, setCookie, showNotification,
} from '../utils';

export function* fetchUserOrders() {
  yield put(loaderAction({ field: 'userOrders', value: true }));
  const user = yield select(fromStore.userSelector);
  const orders = yield call(request, { method: 'get', url: restUrls.userOrders });
  yield put(setUser({ ...user, orders }));
  yield put(loaderAction({ field: 'userOrders', value: false }));
}

export function* fetchCurrentUser() {
  yield put(loaderAction({ field: 'user', value: true }));
  const jwt = yield getCookie('auth');
  if (jwt) {
    const user = yield call(request, { method: 'get', url: restUrls.currentUser });
    yield put(setUser({ ...user, orders: [] }));
    yield put(fetchOrdersUser());
  }
  yield put(loaderAction({ field: 'user', value: false }));
}

export function* updateCurrentUser(data) {
  const user = yield select(fromStore.userSelector);
  const result = yield call(request, { method: 'put', url: `${restUrls.users}/${user.id}`, data });
  yield put(updateUser({ ...result, orders: user.orders || [] }));
}

const getError = (result) => {
  if (!result) return undefined;
  if (result === 'error_code') return 'error_code';
  if (result.error) return result.error;

  return undefined;
};

const getResult = (result) => {
  if (!result) return undefined;
  if (result === 'code_send') return 'code_send';
  if (result.result === 'code_send') return 'code_send';
  if (result.authNumber) return result.authNumber;

  return undefined;
};

const getRoute = (mode) => {
  if (mode === 'phoneInside') return 'pc';
  if (mode === 'telegram') return 'tgcode-sms';

  return undefined;
};

const getUrl = (mode, authNumber) => {
  if (mode === 'sms') return restUrls.phonecodes;
  if (mode === 'telegram' || mode === 'phoneInside') return restUrls.authComposer;
  if (mode === 'phoneOutside') {
    if (authNumber) return restUrls.authWaitCall;

    return restUrls.generateAuthPhone;
  }

  return undefined;
};

function* authUserHandler({
  payload: {
    login, code, userCode, mode, authNumber,
  },
}) {
  const settings = yield select(settingsSelector);
  yield put(loaderAction({ field: 'auth', value: true }));
  yield put(setAuthError(null));

  const route = getRoute(mode);
  const url = getUrl(mode, authNumber);

  const phone = regexpPhone(login);
  const token = md5(`${settings.token}${phone}`);

  const result = yield call(request, {
    method: 'post',
    url,
    data: {
      token, phone, code, userCode, route, authNumber,
    },
  });

  const resultData = getResult(result);
  const error = getError(result);

  if (resultData === 'code_send') {
    yield put(changeAuthStep(mode === 'telegram' ? '3' : '2'));
    yield put(loaderAction({ field: 'auth', value: false }));

    return;
  }

  if (resultData) {
    yield put(changeAuthNumber(resultData));
    yield put(changeAuthStep('2'));
    yield put(loaderAction({ field: 'auth', value: false }));

    return;
  }

  if (result.jwt) {
    yield setCookie('auth', result.jwt);
    yield put(setUser({ ...result.user, orders: [] }));
    yield put(fetchOrdersUser());
    yield put(authSuccess());
    yield put(cancelOrder());
    yield showNotification({
      text: 'Номер телефона подтвержден',
      icon: 'success',
    });
    yield put(changeAuthNumber(null));
    yield put(loaderAction({ field: 'auth', value: false }));

    return;
  }

  if (error) {
    yield put(setAuthError(error));
    yield put(loaderAction({ field: 'auth', value: false }));
  }
}

function* onLogout() {
  yield put(setUser(null));
  yield put(cancelOrder());
  yield deleteCookie('auth');
}

function* onUpdateUserData({ payload: data }) {
  yield put(loaderAction({ field: 'user', value: true }));
  yield updateCurrentUser(data);
  yield put(loaderAction({ field: 'user', value: false }));
}

function* onUpdateUserAdress({ payload: data }) {
  yield put(loaderAction({ field: 'user', value: true }));

  const updatedData = data.privateHouse ? {
    ...data,
    room: null,
    entrance: null,
    floor: null,
  } : { ...data };

  yield updateCurrentUser(updatedData);
  yield put(loaderAction({ field: 'user', value: false }));
  yield put(setActiveModalAction({ field: 'adress', value: false }));
}

function* onUpdateUserPoint({ payload: id }) {
  yield put(loaderAction({ field: 'user', value: true }));
  yield updateCurrentUser({ points: [id] });
  yield put(loaderAction({ field: 'user', value: false }));
  yield put(setActiveModalAction({ field: 'point', value: false }));
}

export default [
  takeLatest(authUser, authUserHandler),
  takeLatest(logoutAction, onLogout),
  takeLatest(updateUserData, onUpdateUserData),
  takeLatest(updateAdressData, onUpdateUserAdress),
  takeLatest(updateUserPoint, onUpdateUserPoint),
  takeLatest(fetchUser, fetchCurrentUser),
  takeLatest(fetchOrdersUser, fetchUserOrders),
];
