import dayjs from 'dayjs';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fromStore } from '../selectors';

export function Footer() {
  const city = useSelector(fromStore.selectedCitySelector);

  const renderPhone = () => {
    const point = city && city.points ? city.points[0] : null;

    if (point && point.phone) {
      return point.phone;
    }
    return '8 (700) 818-80-80';
  };

  return (
    <div style={{ borderTop: '1px solid #f0f2f5' }}>
      <div className="container-xxl footer pt-5 pb-5 d-flex flex-column flex-md-row flex-stack" id="kt_footer">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">
            {dayjs(new Date()).year()}
            ©
          </span>
          <span className="text-gray-800">SUSHIBOX</span>
          <a target="_blank" href="https://apps.apple.com/us/app/sushi-box-доставка-еды/id6444746837" className="bg-white bg-hover-primary rounded" style={{ padding: '2px' }} rel="noreferrer">
            <img className="w-100px" src="/assets/media/as-icon.png" alt="app store" />
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ynbereg.sushibox.kz" className="bg-white bg-hover-danger rounded ms-3" style={{ padding: '2px' }} rel="noreferrer">
            <img className="w-100px" src="/assets/media/gp-icon.png" alt="google play" />
          </a>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <div className="d-none d-md-block" style={{ alignSelf: 'center' }}>
            <span className="text-muted fw-semibold me-1">{renderPhone()}</span>
          </div>
          <li className="menu-item d-none d-md-block">
            <NavLink to="/" className="menu-link px-2">Главное меню</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/contacts" className="menu-link px-2">Контакты</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/policy" className="menu-link px-2">Политика конфиденциальности</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/franchise" className="menu-link ps-2 pe-0">Франшиза</NavLink>
          </li>
        </ul>

      </div>
    </div>
  );
}
