import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addGiftToCart, addToCart, deleteAllFromCart, deleteFromCart, deleteGiftFromCart,
} from '../../actions';
import { fromStore } from '../../selectors';
import { getImageUrl } from '../../utils';

export function CartGood({
  item, className, gift, isPromo,
}) {
  const dispatch = useDispatch();
  const itemInCart = useSelector(fromStore.goodCartListSelector(item.id));
  const giftInCart = useSelector(fromStore.giftCartSelector);
  const goodInCart = gift ? (giftInCart && giftInCart.id === item.id) : itemInCart;

  const {
    id, name, image, category, activeModName, price, count, isStop,
  } = item;

  const handleAddToCart = () => {
    if (gift) {
      dispatch(addGiftToCart(item));
      return;
    }
    dispatch(addToCart(id));
  };

  const handleDeleteFromCart = () => {
    if (gift) {
      dispatch(deleteGiftFromCart());
      return;
    }
    dispatch(deleteFromCart(id));
  };

  const onDelete = () => {
    dispatch(deleteAllFromCart(id));
  };

  /*
  useEffect(() => {
    if (isStop) {
      dispatch(deleteAllFromCart(id));
    }
  }, [isStop]);
  */

  const renderButtons = (
    <div className="position-relative me-5">
      <button onClick={handleDeleteFromCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
      <input type="text" className="form-control h-35px fs-4 text-center w-125px form-control-solid border-0" readOnly="readonly" value={count} />
      <button disabled={gift} onClick={handleAddToCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
    </div>
  );

  const renderButton = (
    <div aria-hidden onClick={handleAddToCart} className="btn btn-sm btn-dark btn-hover-scale me-5 flex-column-fluid">
      <span className="svg-icon svg-icon-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
        </svg>

      </span>
      В корзину
    </div>
  );

  const renderCounterButtons = () => {
    if (isPromo) {
      return (
        <div className="d-flex flex-stack">
          <span style={{ whiteSpace: 'nowrap' }} className="text-gray-800 fw-bolder fs-6">{`${price} ₸`}</span>
        </div>
      );
    }

    if (isStop) {
      return (
        <div className="d-flex flex-stack">
          <div className="text-muted fw-bold d-block me-3">Товар недоступен</div>
          <button onClick={onDelete} className="btn btn-sm btn-icon btn-active-light-danger" type="button">
            <span className="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
              </svg>
            </span>
          </button>
        </div>
      );
    }

    return (
      <div className="d-flex flex-stack">
        {!goodInCart ? renderButton : renderButtons}
        <span style={{ whiteSpace: 'nowrap' }} className="text-gray-800 fw-bolder fs-6">{`${price} ₸`}</span>
      </div>
    );
  };

  return (
    <>
      <div className={className || 'd-flex flex-stack'}>
        <div className="d-flex align-items-center me-3">
          <img src={getImageUrl(image)} className="me-4 w-50px card-rounded" alt="" />
          <div className="flex-grow-1">
            <span className="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">{name}</span>
            <span className="text-gray-400 fw-bold d-block fs-7">
              {category.title}
              {' '}
              {activeModName && <span className="badge badge-dark badge-sm ms-2 px-2 py-1">{`${activeModName}`}</span>}
              {gift && <span className="badge badge-danger badge-sm ms-2 px-2 py-1">FREE</span>}
            </span>
          </div>
        </div>
        {renderCounterButtons()}
      </div>
      <div className="separator separator-dashed my-4" />
    </>
  );
}

CartGood.defaultProps = {
  className: '',
  gift: false,
  isPromo: false,
};

CartGood.propTypes = {
  item: PropTypes.object.isRequired,
  gift: PropTypes.bool,
  isPromo: PropTypes.bool,
  className: PropTypes.string,
};
