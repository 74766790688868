import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { useDispatch, useSelector } from 'react-redux';
import { Img } from 'react-image';
import { setActiveModalAction } from '../../actions';
import { fromStore } from '../../selectors';
import { getIdGood, getImageUrl, numberDevider } from '../../utils';
import { AddCartButton } from './AddCartButton';
import { GoodPrice } from './GoodPrice';
import { FavotiteButton } from './FavotiteButton';
import { MEASURE } from '../../constants';
import { PizzaCard } from './PizzaCard';
import { Loader } from '../../components';
import { Slide } from './Slide';

export function Card({ id }) {
  const dispatch = useDispatch();
  const itemId = getIdGood(id);
  const item = useSelector(fromStore.goodsByIdSelector(itemId));
  const categoryIdPizza = useSelector(fromStore.getCategorySettingsId('pizza'));
  if (!item) return null;

  const isPizza = item.category.id === categoryIdPizza;
  if (isPizza) return <PizzaCard id={id} />;

  const cartId = String(id);
  const weight = `${MEASURE[item.measure].title}: ${numberDevider(item.weight)} ${MEASURE[item.measure].value}`;
  const weightdop = item.weightdop && item.measuredop ? `${MEASURE[item.measuredop].title}: ${numberDevider(item.weightdop)} ${MEASURE[item.measuredop].value}` : null;

  const onClickDetail = useCallback(() => {
    dispatch(setActiveModalAction({ field: 'detail', value: id }));
  }, []);

  const renderImage = useMemo(() => (
    <div className="text-center">
      <Img
        alt={item.name}
        src={getImageUrl(item.image)}
        className="mw-100 card-rounded"
        loader={<Loader image />}
        onClick={onClickDetail}
      />
    </div>
  ), [item.image, onClickDetail]);

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const renderCarousel = useMemo(() => (
    <div onClick={stopPropagation} aria-hidden="true">
      <Swiper navigation modules={[Navigation, Pagination]}>
        <SwiperSlide>
          <Slide name={item.name} image={item.image} click={onClickDetail} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide name={item.name} image={item.secondImage} click={onClickDetail} />
        </SwiperSlide>
      </Swiper>
    </div>
  ), [item.name, item.image, item.secondImage, onClickDetail, stopPropagation]);

  return (
    <div key={id} onClick={onClickDetail} aria-hidden className="col-xl-4">
      <div className="card mb-xl-8 border-hover-dark cursor-pointer card-xl-stretch">
        <div className="card-header border-0 pt-5">
          <div className="d-flex flex-stack w-100 align-items-start">
            <h3 className="card-title align-items-start flex-column m-0">
              <span className="card-label fw-bolder text-dark">{item.name}</span>
              <div className="d-flex align-items-center mt-2">
                <span className="badge badge-dark">{weight}</span>
                {weightdop && <span className="badge badge-secondary ms-1">{weightdop}</span>}
                {item.hot && <img title="Острое" className="ms-1 w-15px" src="/assets/media/pepper.png" alt="острое" />}
                {item.vegan && <img title="Веган" className="ms-1 w-15px" src="/assets/media/vegan.png" alt="веган" />}
              </div>
            </h3>
            <FavotiteButton id={cartId} />
          </div>
        </div>
        <div className="card-body pt-5 d-flex flex-column justify-content-end h-100">
          {item.secondImage ? renderCarousel : renderImage}
          <div className="d-flex flex-stack mt-10">
            <GoodPrice price={item.price} oldPrice={item.oldPrice} id={cartId} />
            <AddCartButton isStop={item.isStop} id={cartId} />
          </div>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
};
