/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { fromStore, settingsSelector } from '../../../../selectors';
import { useOnClickOutside } from '../../../../hooks';
import { Icon, IconTypes } from '../../../../components';
import { addToCartPromo, clearPromotion, setPromotion } from '../../../../actions';
import { numberDevider, promocodeAdapter, promoGoodsAdapter } from '../../../../utils';
import { CartGood } from '../../../cart/CartGood';

function Good({ data }) {
  const { value: id, persent, sale } = data;
  const good = useSelector(fromStore.goodsByIdSelector(id));
  const price = persent ? Math.round(good.price - (good.price * (sale / 100))) : sale;

  return (
    <CartGood isPromo item={{ ...good, price }} />
  );
}

Good.defaultProps = {
  data: null,
};

Good.propTypes = {
  data: PropTypes.object,
};

function Promotion({ promotion, onClose }) {
  const {
    type, name, minPrice, value, persent, goods, category,
  } = promotion;
  const dispatch = useDispatch();
  const сostGoods = useSelector(fromStore.totalGoodsPriceWithSaleSelector);
  const isValidPrice = +сostGoods >= +minPrice;

  const onSelect = () => {
    const data = promocodeAdapter(promotion);
    if (type === 'addGoods') {
      dispatch(addToCartPromo(promoGoodsAdapter(data.goods)));
    }
    dispatch(setPromotion(promocodeAdapter(promotion)));
    onClose();
  };

  const renderOrder = () => (
    <div className="d-flex mt-5 align-items-center fs-4 border-1">
      <div className="me-2">Скидка на заказ</div>
      <div className="fs-2 text-danger fw-bold">{`${value}${persent ? '%' : ' ₸'}`}</div>
    </div>
  );

  const renderGoods = () => (
    <div className="mt-5">
      {goods.map((good) => <Good data={good} />)}
    </div>
  );

  const renderCategory = () => (
    <div className="d-flex mt-5 align-items-center fs-4 border-1">
      <div className="me-2">Скидка на категорию {category.label}</div>
      <div className="fs-2 text-danger fw-bold">{`${value}${persent ? '%' : ' ₸'}`}</div>
    </div>
  );

  const renderAddGoods = () => (
    <div className="mt-5">
      {goods.map((good) => <Good data={good} />)}
    </div>
  );

  const content = () => {
    switch (type) {
      case 'order': return renderOrder();
      case 'goods': return renderGoods();
      case 'category': return renderCategory();
      case 'addGoods': return renderAddGoods();
      default: return null;
    }
  };

  return (
    <div className="mb-5 border p-5 rounded-2">
      <h5 className="fs-4">{name}</h5>
      <span className="fs-5">
        Условия: сумма заказа
        <span className="fw-bold text-danger">
          {` от ${numberDevider(minPrice)} `}
          ₸
        </span>
      </span>
      {content()}
      {!isValidPrice && (
      <div className="mt-5 fs-6">
        Добавьте в корзину товаров еще на {numberDevider(+minPrice - +сostGoods)} ₸, чтобы воспользоваться предложением
      </div>
      )}

      <div className="mt-5">
        <button onClick={onSelect} disabled={!isValidPrice} type="button" className="btn btn-sm btn-danger">
          Применить
        </button>
      </div>
    </div>
  );
}

Promotion.defaultProps = {
  promotion: null,
  onClose: () => {},
};

Promotion.propTypes = {
  promotion: PropTypes.object,
  onClose: PropTypes.func,
};

function Modal({ onClose }) {
  const ref = useRef();
  const promotions = useSelector(fromStore.promotionsListSelector);

  const onCloseHandler = () => {
    onClose();
  };

  useOnClickOutside(ref, onCloseHandler);

  const renderHeader = (
    <div className="d-flex flex-stack mb-7">
      <div className="me-5">
        <span className="text-gray-800 fs-1 lh-sm fw-bolder">Специальные предложения</span>
        {promotions.length > 1 && (
        <div className="text-muted fw-bold fs-5 mt-3">
          {`Выберите 1 из ${promotions.length} предложений`}
        </div>
        )}
      </div>
      <button type="button" onClick={onClose} className="btn btn-light flex-shrink-0 align-self-start fs-7 px-2 py-2">
        <Icon type={IconTypes.modalClose} className="svg-icon svg-icon-muted svg-icon-1 me-0" />
      </button>
    </div>
  );

  const renderItem = (promotion) => <Promotion key={promotion.id} promotion={promotion} onClose={onCloseHandler} />;

  const content = () => (
    <div className="scroll-y mh-500px">
      {promotions.map(renderItem)}
    </div>
  );

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div ref={ref} className="modal-content">
          <div className="modal-body scroll-y p-0 m-0">
            <div className="card card-flush border-0 h-100">
              <div className="card-body py-9">
                {renderHeader}
                <div className="row gx-9 h-100">
                  <div className="col-lg-12 col-sm-12">
                    {content()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  onClose: () => {},
};

Modal.propTypes = {
  onClose: PropTypes.func,
};

export function Promotions() {
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);
  const settings = useSelector(settingsSelector);
  const promotions = useSelector(fromStore.promotionsListSelector);
  const selectedPromotion = useSelector(fromStore.promotionSelector);
  const isActivePromocode = useSelector(fromStore.isActivePromocodeSelector);
  const isEnabled = settings.promotions && !isEmpty(promotions);
  const сostGoods = useSelector(fromStore.totalGoodsPriceWithSaleSelector);

  const onClose = () => setShow(false);
  const onShow = () => setShow(true);

  const onClearPromotion = () => {
    dispatch(clearPromotion());
    onShow();
  };

  useEffect(() => {
    if (!isEnabled || selectedPromotion || isActivePromocode) return;
    const isOnePromo = promotions.length === 1;

    if (settings.isAutoLinkPromo && isOnePromo) {
      const promotion = promotions[0];
      const { minPrice, type } = promotion;
      const isValidPrice = +сostGoods >= +minPrice;
      if (isValidPrice) {
        const data = promocodeAdapter(promotion);
        if (type === 'addGoods') {
          dispatch(addToCartPromo(promoGoodsAdapter(data.goods)));
        }
        dispatch(setPromotion(promocodeAdapter(promotion)));
        return;
      }
    }

    if (settings.autoPromo) {
      setTimeout(() => onShow(), settings.autoPromo * 1000);
    }
  }, []);

  if (!isEnabled) return null;

  return (
    <>
      <div className="mb-7 border px-7 rounded py-3">
        {selectedPromotion ? (
          <h4 className="d-flex flex-stack align-items-center flex-wrap mb-0">
            <div className="d-flex align-items-center" style={{ flex: 1 }}>
              <span className="badge badge-danger me-3">Промо</span>
              <span>{selectedPromotion.name}</span>
            </div>
            <button onClick={onClearPromotion} type="button" className="btn btn-sm btn-secondary">
              Отменить
            </button>
          </h4>
        ) : (
          <h4 className="d-flex flex-stack align-items-center flex-wrap mb-0">
            <span>Акции и специальные предложения</span>
            <button disabled={isShow || isActivePromocode} onClick={onShow} type="button" className="btn btn-sm btn-danger">
              Посмотреть
            </button>
          </h4>
        )}
      </div>
      {isShow && <Modal onClose={onClose} />}
      {isShow && <div className="modal-backdrop fade show" />}
    </>

  );
}
